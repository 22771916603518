import { getDefaultStore, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { fetchDataFromBackend } from '@/src/communication/socket/dataRequest';
import { Gender, PictureSetStatus, PictureTypes, SocketEmits } from '@api-types';
import {
  inTrainingAtom,
  readyPictureSetsAtom,
  reloadPictureSetAtom,
  selectedPictureSetAtom,
  inTrainingPictureSetsAtom,
  selectedPreviewGenderAtom,
  selectedFoundationPictureAtom,
  foundationPicturesAtom,
  pictureSetInitializedAtom,
} from '@/src/atoms/upload.states';
import { RESET } from 'jotai/utils';
import { useRouterQuery } from './useRouterQuery';
import { useRouter } from 'next/router';
import {
  activeRatioAtom,
  appearanceAgeAtom,
  appearanceEyeColorAtom,
  appearanceHairColorAtom,
  appearancehaircutAtom,
  appearancehaircutNameAtom,
  appearanceStyleAtom,
  customInputAtom,
  detailEnhancerAtom,
  editModeAtom,
  editModeChatAtom,
  renderModeAtom,
  selectedEyeColorAtom,
  selectedHairColorAtom,
  similarityStrengthAtom,
} from '@/sections/dashboard/menu/Content.hooks';
import { socketAtom } from '@/providers/WebSocketProvider';
import { appActiveSectionModeAtom, pictureSetsDataIsLoadedAtom, pictureSetsDataAtom } from '../atoms/useApp.states';
import {
  currentConversationIdAtom,
  conversationsIsLoadedAtom,
  localMessagesAtom,
  messagesAtom,
  introModeAtom,
  conversationsAtom,
} from '@/sections/dashboard/chatInterface/Chat.states';

const findHighestIdPictureSet = (data: PictureTypes.PictureSetViewModel[]) =>
  data.reduce((prev, current) => {
    if (!current || !prev) return current || prev; // Handle null 'current' and 'prev'
    return prev.id > current.id ? prev : current;
  });

export const usePictureSet = () => {
  const socket = useAtomValue(socketAtom);
  const [selectedPictureSet, setSelectedPictureSet] = useAtom(selectedPictureSetAtom);
  const setSelectedPreviewGender = useSetAtom(selectedPreviewGenderAtom);
  const setPictureSetInitialized = useSetAtom(pictureSetInitializedAtom);
  const [readyPictureSets, setReadyPictureSets] = useAtom(readyPictureSetsAtom);
  const router = useRouter();
  const switchToPictureSetId = router.query.switchToPictureSetId ? Number(router.query.switchToPictureSetId) : undefined;

  useEffect(() => {
    if (!socket) return;

    const loadPictureSets = async () => {
      const data = await fetchDataFromBackend<PictureTypes.PictureSetViewModel[]>(socket, SocketEmits.GetPictureSetsDataWithIds, null);

      const readySets = data?.filter((set) => set?.status === PictureSetStatus.ready) ?? [];
      setReadyPictureSets(readySets);

      // Handle switchToPictureSetId if present
      if (switchToPictureSetId) {
        const switchToSet = readySets.find((set) => set?.id === switchToPictureSetId);
        if (switchToSet) {
          setSelectedPictureSet(switchToSet);
          setSelectedPreviewGender(switchToSet.gender);
          // Clean up URL
          const { switchToPictureSetId: _, ...restQuery } = router.query;
          router.replace({ pathname: router.pathname, query: restQuery }, undefined, { shallow: true });
        }
      }
      // Otherwise set initial picture set if we have one
      else if (readySets.length > 0 && !selectedPictureSet) {
        const highestIdSet = findHighestIdPictureSet(readySets);
        setSelectedPictureSet(highestIdSet);
        setSelectedPreviewGender(highestIdSet?.gender ?? null);
      }

      // Initialize AFTER everything is loaded
      setPictureSetInitialized(true);
    };

    loadPictureSets();
  }, [socket, switchToPictureSetId]);

  return { readyPictureSets, selectedPictureSet, setSelectedPictureSet };
};

export const resetGlobalAtoms = () => {
  const store = getDefaultStore();
  store.set(selectedPictureSetAtom, RESET);
  store.set(inTrainingAtom, RESET);
  store.set(readyPictureSetsAtom, RESET);
  store.set(inTrainingPictureSetsAtom, RESET);
  store.set(selectedFoundationPictureAtom, RESET);
  store.set(foundationPicturesAtom, RESET);
  store.set(selectedPreviewGenderAtom, RESET);
  store.set(editModeAtom, RESET);

  // Reset only atomWithStorage atoms from Content.hooks.ts
  store.set(appearancehaircutAtom, RESET);
  store.set(appearancehaircutNameAtom, RESET);
  store.set(appearanceHairColorAtom, RESET);
  store.set(appearanceEyeColorAtom, RESET);
  store.set(appearanceStyleAtom, RESET);
  store.set(appearanceAgeAtom, RESET);
  store.set(selectedHairColorAtom, RESET);
  store.set(selectedEyeColorAtom, RESET);
  store.set(customInputAtom, RESET);
  store.set(detailEnhancerAtom, RESET);
  store.set(similarityStrengthAtom, RESET);
  store.set(activeRatioAtom, RESET);
  store.set(renderModeAtom, RESET);
  store.set(editModeAtom, RESET);
  store.set(appActiveSectionModeAtom, RESET);
  store.set(pictureSetsDataAtom, null);
  store.set(pictureSetsDataIsLoadedAtom, false);
  store.set(messagesAtom, RESET);
  store.set(localMessagesAtom, RESET);
  store.set(currentConversationIdAtom, null);
  store.set(conversationsIsLoadedAtom, false);
  store.set(introModeAtom, false);
  store.set(conversationsAtom, []);
  store.set(socketAtom, null);
  store.set(editModeChatAtom, RESET);
  store.set(selectedPreviewGenderAtom, null);
  store.set(pictureSetInitializedAtom, false);
};
