import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import localStorageAvailable from '../utils/localStorageAvailable';
import { defaultLang } from './config-lang';

let lng = defaultLang.value;

const storageAvailable = localStorageAvailable();

if (storageAvailable) {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    resources: {}, // Start with an empty resources object
  });

const loadLanguageResources = async (lang: string) => {
  switch (lang) {
    case 'en':
      const enLocales = await import('./langs/en');
      i18n.addResourceBundle('en', 'translations', enLocales.default);
      break;
    case 'de':
      const deLocales = await import('./langs/de');
      i18n.addResourceBundle('de', 'translations', deLocales.default);
      break;
    case 'uk':
      const ukLocales = await import('./langs/uk');
      i18n.addResourceBundle('uk', 'translations', ukLocales.default);
      break;
    case 'ru':
      const ruLocales = await import('./langs/ru');
      i18n.addResourceBundle('ru', 'translations', ruLocales.default);
      break;
    case 'es':
      const esLocales = await import('./langs/es');
      i18n.addResourceBundle('es', 'translations', esLocales.default);
      break;
    case 'fr':
      const frLocales = await import('./langs/fr');
      i18n.addResourceBundle('fr', 'translations', frLocales.default);
      break;
    case 'zh':
      const zhLocales = await import('./langs/zh');
      i18n.addResourceBundle('zh', 'translations', zhLocales.default);
      break;
    case 'pl':
      const plLocales = await import('./langs/pl');
      i18n.addResourceBundle('pl', 'translations', plLocales.default);
      break;
    default:
      break;
  }
};

i18n.on('languageChanged', (lang) => {
  if (!i18n.hasResourceBundle(lang, 'translations')) {
    loadLanguageResources(lang);
  }
});

export const changeLanguage = async (lang: string) => {
  if (!i18n.hasResourceBundle(lang, 'translations')) {
    await loadLanguageResources(lang);
  }
  i18n.changeLanguage(lang);
};

export default i18n;
