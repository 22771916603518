import { atom } from 'jotai';
import { UploadedImage } from '../types/upload.types';
import { atomWithReset, atomWithStorage } from 'jotai/utils';
import { Gender, PictureTypes } from '@api-types';
import { FoundationPicturesViewModel } from '@models/pictureTypes';

export const reloadPictureSetAtom = atom<boolean>(false);
export const readyPictureSetsAtom = atomWithReset<PictureTypes.PictureSetViewModel[]>([]);
export const selectedPictureSetAtom = atomWithStorage<PictureTypes.PictureSetViewModel | null>('selectedPictureSetAtom', null);
export const selectedPictureSetInProfileAtom = atomWithReset<PictureTypes.PictureSetViewModel | null>(null);
export const selectedPreviewGenderAtom = atomWithStorage<Gender | null>('selectedPreviewGenderAtom', null);

export const customBreadcrumbPageNameAtom = atom<string | null>(null);
export const inTrainingPictureSetsAtom = atomWithReset<PictureTypes.PictureSetViewModel[]>([]);

// Help Popover
export const openPopupAtom = atom<boolean>(false);

// Uploader
export const uploadImagesAtom = atom<UploadedImage[]>([]);

// PictureSet
export const openGenderDialogAtom = atom<boolean>(false);

// Dialog
export const pictureSetConfirmDeleteDialogAtom = atom(false);

// Training
export const trainingProgressAtom = atom<Record<string, number>>({});
export const inTrainingAtom = atomWithReset(false);
export const trainingErrorAtom = atom(false);
export const trainingReadyAtom = atom(false);

// UploadManager
export const uploadProgressAtom = atom(0);
export const processingProgressAtom = atom(0);
export const isImageProcessingAtom = atom(false);
export const isUploadingAtom = atom(false);
export const isProcessingCompleteAtom = atom(false);
export const uploadIsSavingAtom = atom(false);
export const saveUploadedImagesAtom = atom(false);

// Foundation Pictures
export const reloadFoundationPicturesAtom = atom<boolean>(false);
export const selectedFoundationPictureAtom = atomWithStorage<FoundationPicturesViewModel | null>('selectedFoundationPictureAtom', null);
export const foundationPicturesAtom = atomWithStorage<FoundationPicturesViewModel[] | null>('foundationPicturesAtom', null);
export const foundationPicturesUploadButtonBlinkAtom = atom(false);
export const foundationPicturesDeletedAtom = atom(false);
export const selectedPhotoEditorSourcePictureAtom = atomWithStorage<string | null>('selectedPhotoEditorSourcePictureAtom', null);
export const isFoundationPicturesLoadedAtom = atom(false);
export const photoEditorGenerateLoadingAtom = atom(false);
// New Atom to track if the selected Pictureset is loaded and ready

export const pictureSetInitializedAtom = atom<boolean>(false);
