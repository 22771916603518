export enum SocketEvents {
  VideoGenerationInitiated = 'VideoGenerationInitiated',
  VideoGenerationStarted = 'VideoGenerationStarted',
  VideoGenerationFailed = 'VideoGenerationFailed',
  VideoGenerationCompleted = 'VideoGenerationCompleted',
  VideoStatusUpdated = 'VideoStatusUpdated',
  OpenAiResponse = 'OpenAiResponse',
  OpenAiError = 'OpenAiError',
  ServerRestarted = 'ServerRestarted',
  Unauthenticated = 'Unauthenticated',
  Authenticated = 'Authenticated',
  RegistrationFailed = 'RegistrationFailed',
  NewPicturesGenerated = 'NewPictures',
  JobFailed = 'JobFailed',
  Logout = 'Logout',
  ModelTrainingProgress = 'ModelTrainingProgress',
  ModelTrainingCompleted = 'ModelTrainingCompleted',
  ModelTrainingFailed = 'ModelTrainingFailed',
  Connect = 'connect',
  ConnectError = 'connect_error',
  Disconnect = 'disconnect',
  UserBalanceChanged = 'UserBalanceChanged',
  ImageGenerationJobCounterChanged = 'ImageGenerationJobCounterChanged',
  SubmitContactForm = 'SubmitContactForm',
  UpdateUserPassword = 'updateUserPassword',
  R2ImageUploadProgress = 'R2ImageUploadProgress',
  ProcessApplePayment = 'ProcessApplePayment',
  NewMessage = 'NewMessage',
  AIResponseStream = 'AIResponseStream',
  AIResponseStart = 'AIResponseStart',
  AIResponseEnd = 'AIResponseEnd',
}

export enum SocketEmits {
  CreateStory = 'CreateStory',
  DeleteStory = 'DeleteStory',
  SaveCharacterPrompt = 'SaveCharacterPrompt',
  SyncStoryVideoStatus = 'SyncStoryVideoStatus',
  SaveCustomStylePrompt = 'SaveCustomStylePrompt',
  SetActiveStylePrompt = 'SetActiveStylePrompt',
  UpdateSceneImageStatus = 'UpdateSceneImageStatus',
  SaveStylePreviewImages = 'SaveStylePreviewImages',
  SaveCharacterPreviewImages = 'SaveCharacterPreviewImages',
  SaveCustomCharacterPrompt = 'SaveCustomCharacterPrompt',
  SetActiveCharacterPrompt = 'SetActiveCharacterPrompt',
  AddStoryVideo = 'AddStoryVideo',
  GetScenePrompt = 'GetScenePrompt',
  UpdateScenePrompt = 'UpdateScenePrompt',
  InitiateStoryVideo = 'InitiateStoryVideo',
  UpdateVideoStatus = 'UpdateVideoStatus',
  UpdateStoryStep = 'UpdateStoryStep',
  UpdateStoryState = 'UpdateStoryState',
  GetStoryState = 'GetStoryState',
  UpdateStoryVideos = 'UpdateStoryVideos',
  GetStory = 'GetStory',
  GetUserStories = 'GetUserStories',
  UpdateStoryImages = 'UpdateStoryImages',
  GenerateSceneImages = 'GenerateSceneImages',
  SelectSceneImage = 'SelectSceneImage',
  GenerateCharacterPrompt = 'GenerateCharacterPrompt',
  GenerateCharacterImages = 'GenerateCharacterImages',
  GetCharacterPrompts = 'GetCharacterPrompts',
  GetStoryStylePrompts = 'GetStoryStylePrompts',
  UpdateSceneHeadline = 'UpdateSceneHeadline',
  UpdateScene = 'UpdateScene',
  ReviseStory = 'ReviseStory',
  RegenerateStylePrompt = 'RegenerateStylePrompt',
  UpdateStoryTitle = 'UpdateStoryTitle',
  NewVideoGenerationRequest = 'NewVideoGenerationRequest',
  GenerateStylePrompt = 'GenerateStylePrompt',
  GenerateStyleImages = 'GenerateStyleImages',
  GetVideos = 'GetVideos',
  DeleteVideo = 'DeleteVideo',
  GeneratePicturesArtist = 'GeneratePicturesArtist',
  SendInitialGreeting = 'SendInitialGreeting',
  CreateConversation = 'CreateConversation',
  SendMessage = 'SendMessage',
  UpdateConversationSummary = 'UpdateConversationSummary',
  FetchMessages = 'fetch_messages',
  GetUserConversations = 'GetUserConversations',
  GetUserConversationsCount = 'GetUserConversationsCount',
  GetConversationHistory = 'GetConversationHistory',
  CloseConversation = 'CloseConversation',
  JoinConversation = 'JoinConversation',
  LeaveConversation = 'LeaveConversation',
  SendOpenAiMessage = 'SendOpenAiMessage',
  GetOpenAiResponse = 'GetOpenAiResponse',
  LoginCredentials = 'LoginCredentials',
  LoginApple = 'LoginApple',
  Logout = 'Logout',
  GetPicturesData = 'GetPicturesData',
  GetUserPicturesData = 'GetUserPicturesData',
  GetUserPicturesCount = 'GetUserPicturesCount',
  GetPhotoEditorPicturesData = 'GetPhotoEditorPicturesData',
  GetPicturesCountFromPortraitSet = 'GetPicturesCountFromPortraitSet',
  GetPicturesQueue = 'GetPicturesQueue',
  DeleteNewPictures = 'DeleteNewPictures',
  DeletePictures = 'DeletePictures',
  DeletePictureSet = 'DeletePictureSet',
  GetPictureSetsData = 'GetPictureSetsData',
  GetPictureSetsDataWithIds = 'GetPictureSetsDataWithIds',
  UpdatePictureSet = 'UpdatePictureSet',
  GetPictureSetsInTraining = 'GetPictureSetsInTraining',
  GetCategories = 'GetCategories',
  GetPortraitSetsData = 'GetPortraitSetsData',
  GetPreviewPicturesByGender = 'GetPreviewPicturesByGender',
  GetPromptText = 'GetPromptText',
  GetPortraitSetsDataByIdWithUserDefaultOptions = 'GetPortraitSetsDataByIdWithUserDefaultOptions',
  GetLatestPictures = 'GetLatestPictures',
  GetLastNPictures = 'GetLastNPictures',
  UpdateUser = 'UpdateUser',
  DeleteUser = 'DeleteUser',
  UpdateUserPassword = 'UpdateUserPassword',
  UpdateNotificationSettings = 'UpdateNotificationSettings',
  UpdateLanguageSetting = 'UpdateLanguageSetting',
  GetNotificationSettings = 'GetNotificationSettings',
  GetUserCoins = 'GetUserCoins',
  GetCoinPackages = 'GetCoinPackages',
  GetCoinPackagesForUser = 'GetCoinPackagesForUser',
  DecreaseDownload = 'DecreaseDownload',
  GeneratePictures = 'GeneratePictures',
  GeneratePhotoEditorImage = 'GeneratePhotoEditorImage',
  DeletePortraitSetDefaults = 'DeletePortraitSetDefaults',
  SavePortraitSetDefaults = 'SavePortraitSetDefaults',
  StartTestTraining = 'StartTestTraining',
  SubmitContactForm = 'SubmitContactForm',
  BuyCoinPackage = 'BuyCoinPackage',
  SaveDeviceToken = 'SaveDeviceToken',
  DeleteDeviceToken = 'DeleteDeviceToken',
  ProcessApplePayment = 'ProcessApplePayment',
  GetFoundationPictures = 'GetFoundationPictures',
  DeleteFoundationPicture = 'DeleteFoundationPicture',
}
