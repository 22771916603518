import { Gender } from '@models/enums';
import { PictureViewModel, ImageGenerationJobViewModel } from '@models/pictureTypes';
import { SimpleVideoViewModel, VideoStatusInfo } from '@models/video/video';
import {
  AspectRatio as PrismaAspectRatio,
  StoryVideoStatus as PrismaStoryVideoStatus,
  VideoStatus,
  Prisma,
  PictureStatus,
} from '@prisma/client';

export type AspectRatio = PrismaAspectRatio;
export const AspectRatio = PrismaAspectRatio;
export type StoryVideoStatus = PrismaStoryVideoStatus;

export interface ServerSelectedImages {
  [sceneNumber: number]: {
    [imageIndex: number]: string | SceneImageState;
  };
}

export const ASPECT_RATIO_MAP = {
  Portrait: '7:9',
  Square: '1:1',
  Mobile: '4:7',
  Landscape: '7:4',
} as const;

export interface Story {
  id: number;
  userId: number;
  title: string;
  baseIdea: string;
  finalStory: string | null;
  status: 'draft' | 'inProgress' | 'completed' | 'archived';
  aspectRatio: AspectRatio;
  createdAt: Date;
  updatedAt: Date;
  scenes?: Scene[];
  currentStep: number;
  styleImages: StoryImageGroup[] | null;
  characterImages: StoryImageGroup[] | null;
  selectedImages: SelectedImagesState | null;
  lockedImages: Record<number, string[]> | null;
  videos?: {
    id: number;
    storyId: number;
    sceneNumber: number;
    videoUrl: string | null;
    status: StoryVideoStatus;
    jobId: string | null;
    createdAt: Date;
    updatedAt: Date;
    videoId: number | null;
    video?: {
      id: number;
      videoUrl: string | null;
      status: VideoStatus;
      statusInfo: VideoStatusInfo | null;
      pictureId: number;
      percent?: number;
    };
  }[];
  thumbnail?: string;
  activeStylePromptId: number | null;
  activeCharacterPromptId: number | null;
}

export type StoryRevision = {
  id: number;
  storyId: number;
  content: string;
  userComment: string | null;
  createdAt: Date;
};

export type StoryGenerationResponse = {
  story: Story;
  revision: StoryRevision | null;
};

// Request types
export type CreateStoryRequest = {
  baseIdea: string;
  sceneCount: number;
  gender: Gender;
  aspectRatio: AspectRatio;
};

export type ReviseStoryRequest = {
  storyId: number;
  currentContent: string;
  userComment: string;
  sceneCount: number;
};

export type UpdateTitleRequest = {
  storyId: number;
  title: string;
};

// Style generation types
export type GenerateStylePromptRequest = {
  storyId: number;
  sceneContent: string;
};

export type GenerateStylePromptResponse = {
  prompt: string;
};

export type GenerateStyleImagesRequest = {
  storyId: number;
  prompt: string;
  pictureSetId: number | null;
  aspectRatio: AspectRatio;
};

export type GenerateStyleImagesResponse = {
  pictures: PictureViewModel[];
  jobs?: ImageGenerationJobViewModel[];
  isPreview?: boolean;
  status?: string;
};

// Response types
export type Scene = {
  id: number;
  sceneNumber: number;
  headline: string;
  content: string;
  prompt?: string;
  storyId: number;
  images?: SceneImage[];
};

export type ReviseStoryResponse = {
  content: string;
  scenes: Scene[];
};

// Component prop types
export interface StyleCreationStepProps {
  onNext: () => void;
  onBack: () => void;
  storyData: StoryData;
  setStoryData: (data: StoryData) => void;
}

export interface StoryData {
  id: number | null;
  userId?: number;
  title: string;
  baseIdea: string;
  finalStory: string | null;
  status: 'draft' | 'inProgress' | 'completed' | 'archived';
  aspectRatio: AspectRatio;
  scenes: Scene[];
  currentStep: number;
  styleImages: StoryImageGroup[] | null;
  characterImages: StoryImageGroup[] | null;
  selectedImages: SelectedImagesState | null;
  lockedImages: Record<number, string[]> | null;
  stylePrompt?: string;
  characterPrompt?: string;
  activeCharacterPromptId?: number | null;
  sceneCount: number;
  videos: (SimpleVideoViewModel & {
    sceneNumber: number;
  })[];
  isLoading?: boolean;
}

// Add these new types
export type UpdateSceneRequest = {
  storyId: number;
  sceneNumber: number;
  updates: {
    headline?: string;
    content?: string;
  };
};

export type UpdateSceneResponse = {
  id: number;
  storyId: number;
  sceneNumber: number;
  headline: string;
  content: string;
};

// Add to existing GenerateStylePromptRequest
export type GetStoryStylePromptsRequest = {
  storyId: number;
};

// Character generation types
export type GenerateCharacterPromptRequest = {
  storyId: number;
};

export type GenerateCharacterPromptResponse = {
  prompt: string;
};

export type GenerateCharacterImagesRequest = {
  storyId: number;
  prompt: string;
  pictureSetId: number | null;
  aspectRatio: AspectRatio;
};

export type GenerateCharacterImagesResponse = {
  pictures: PictureViewModel[];
  jobs?: ImageGenerationJobViewModel[];
  isPreview?: boolean;
  status?: string;
};

export type GetCharacterPromptsRequest = {
  storyId: number;
};

export type SceneImage = {
  id: number;
  imageUrl: string;
  selected: boolean;
  createdAt: string;
  isLoading?: boolean;
};

export type SceneImageGroup = {
  jobId: string;
  pictures: SceneImage[];
  dateGenerated: Date;
  status: string;
  isPreview: boolean;
};

// Scene image generation types
export type GenerateSceneImagesRequest = {
  storyId: number;
  sceneNumber: number;
  count: number;
  pictureSetId: number | undefined;
  aspectRatio: AspectRatio;
};

export type GenerateSceneImagesResponse = {
  pictures: PictureViewModel[];
  jobs: (ImageGenerationJobViewModel & {
    sceneImageId: number;
  })[];
  status?: string;
  isPreview?: boolean;
};

export type SelectSceneImageRequest = {
  storyId: number;
  sceneNumber: number;
  imageUrl: string;
};

export type SceneImagesResponse = {
  images: {
    id: number;
    imageUrl: string;
    selected: boolean;
    createdAt: string;
    sceneId: number;
  }[];
};

export interface StoryImageGroup {
  id: number;
  jobId: string;
  status: string;
  pictureUrl: string;
  pictureThumbUrl: string;
  dateGenerated?: string | Date;
}

export interface SceneImageState {
  jobId: string | null;
  sceneImageId: number;
  status: 'pending' | 'completed' | 'failed';
  imageUrl: string | null;
  pictureId?: number;
  locked?: boolean;
}

export interface SelectedImagesState {
  [sceneNumber: number]: {
    [imageIndex: number]: SceneImageState;
  };
}

// Define strict types for JSON storage
export interface DatabasePicture {
  id: number;
  pictureUrl: string;
  pictureThumbUrl: string;
  dateGenerated: string;
}

export interface DatabaseStoryImageGroup {
  jobId: string;
  pictures: DatabasePicture[];
  dateGenerated: string; // Date as string in database
  status: string;
  isPreview: boolean;
}

export interface StoryState {
  currentStep: number;
  styleImages: StoryImageGroup[] | null;
  characterImages: StoryImageGroup[] | null;
  selectedImages: SelectedImagesState | null;
  lockedImages: Record<number, string[]> | null;
  activeCharacterPromptId: number | null;
  status: 'draft' | 'inProgress' | 'completed' | 'archived';
}

// Add a type for the raw database story
export interface RawDatabaseStory {
  id: number;
  userId: number;
  title: string;
  baseIdea: string;
  finalStory: string | null;
  status: 'draft' | 'inProgress' | 'completed' | 'archived';
  aspectRatio: AspectRatio;
  createdAt: Date;
  updatedAt: Date;
  scenes?: Scene[];
  currentStep: number;
  styleImages: string | null; // JSON string in database
  characterImages: string | null; // JSON string in database
  selectedImages: string | null; // JSON string in database
  lockedImages: string | null; // JSON string in database
  videos?: {
    id: number;
    storyId: number;
    sceneNumber: number;
    videoUrl: string | null;
    status: StoryVideoStatus;
    jobId: string | null;
    createdAt: Date;
    updatedAt: Date;
    videoId: number | null;
    video?: {
      id: number;
      videoUrl: string | null;
      status: VideoStatus;
      statusInfo: VideoStatusInfo | null;
      pictureId: number;
      percent?: number;
    };
  }[];
  activeStylePromptId: number | null;
  activeCharacterPromptId: number | null;
}

// Add a helper function to convert RawDatabaseStory to Story
export function convertRawStoryToStory(rawStory: RawDatabaseStory): Story {
  let thumbnail: string | undefined;
  if (rawStory.selectedImages) {
    const selectedImagesObj = typeof rawStory.selectedImages === 'string' ? JSON.parse(rawStory.selectedImages) : rawStory.selectedImages;
    // Get first selected image
    const firstScene = Object.values(selectedImagesObj)[0];
    if (firstScene) {
      const firstImage = Object.values(firstScene)[0];
      thumbnail = typeof firstImage === 'string' ? firstImage : firstImage.imageUrl;
    }
  } else if (rawStory.styleImages) {
    const styleImages = typeof rawStory.styleImages === 'string' ? JSON.parse(rawStory.styleImages) : rawStory.styleImages;
    if (styleImages?.[0]?.pictureUrl) {
      thumbnail = styleImages[0].pictureUrl;
    }
  }

  return {
    ...rawStory,
    thumbnail,
    styleImages: rawStory.styleImages
      ? typeof rawStory.styleImages === 'string'
        ? JSON.parse(rawStory.styleImages)
        : rawStory.styleImages
      : null,
    characterImages: rawStory.characterImages
      ? typeof rawStory.characterImages === 'string'
        ? JSON.parse(rawStory.characterImages)
        : rawStory.characterImages
      : null,
    selectedImages: rawStory.selectedImages
      ? typeof rawStory.selectedImages === 'string'
        ? JSON.parse(rawStory.selectedImages)
        : rawStory.selectedImages
      : null,
    lockedImages: rawStory.lockedImages
      ? typeof rawStory.lockedImages === 'string'
        ? JSON.parse(rawStory.lockedImages)
        : rawStory.lockedImages
      : null,
    scenes: rawStory.scenes || [],
    videos: rawStory.videos?.map((storyVideo) => ({
      id: storyVideo.id,
      storyId: storyVideo.storyId,
      sceneNumber: storyVideo.sceneNumber,
      videoUrl: storyVideo.videoUrl || storyVideo.video?.videoUrl || null,
      status: storyVideo.status,
      jobId: storyVideo.jobId || null,
      createdAt: storyVideo.createdAt,
      updatedAt: storyVideo.updatedAt,
      videoId: storyVideo.videoId,
      video: storyVideo.video
        ? {
            id: storyVideo.video.id,
            videoUrl: storyVideo.video.videoUrl || null,
            status: storyVideo.video.status,
            statusInfo: storyVideo.video.statusInfo,
            pictureId: storyVideo.video.pictureId,
            percent: storyVideo.video.statusInfo?.percent || 0,
          }
        : undefined,
    })),
    activeStylePromptId: rawStory.activeStylePromptId,
    activeCharacterPromptId: rawStory.activeCharacterPromptId,
  };
}

// Add these new types
export type StoryUpdateRequest = {
  storyId: number;
  updates: Partial<Story>;
};

export type StoryUpdateResponse = Story;

export type AcceptStyleRequest = {
  storyId: number;
  styleImageGroup: StyleImageGroup;
};

export type AcceptStyleResponse = {
  success: boolean;
  story: Story;
};

// Update StyleImageGroup to match what's used in the components
export interface StyleImageGroup {
  jobId: string;
  pictures: PictureViewModel[];
  dateGenerated: Date;
  status: string;
  isPreview: boolean;
  prompt?: string;
}

// First define the preview images structure
export type PreviewImages = {
  jobId: string;
  images: PictureViewModel[];
};

// Update the response types to handle JSON serialization
export type GetStoryStylePromptsResponse = {
  prompts: {
    id: number;
    prompt: string;
    createdAt: Date;
    previewImages: PreviewImages | null;
    isCustom: boolean;
  }[];
};

export type GetCharacterPromptsResponse = {
  prompts: {
    id: number;
    prompt: string;
    createdAt: Date;
    previewImages: PreviewImages | null;
    isCustom: boolean;
  }[];
};

// Update the database types to match Prisma's JSON handling
export interface RawDatabaseStoryStylePrompt {
  id: number;
  prompt: string;
  createdAt: Date;
  previewImages: Prisma.JsonValue;
  isCustom: boolean;
}

export interface RawDatabaseStoryCharacterPrompt {
  id: number;
  prompt: string;
  createdAt: Date;
  previewImages: Prisma.JsonValue;
  isCustom: boolean;
}

// Add these new interfaces
export interface AICallParams {
  model: 'o1-mini' | 'gpt-4o-mini';
  messages: Array<{ role: string; content: string }>;
  temperature?: number;
  maxTokens?: number;
}

export interface OpenAIConfig {
  model: string;
  messages: Array<{ role: string; content: string }>;
  temperature: number;
  max_completion_tokens?: number;
  max_tokens?: number;
}

export interface ImageGenerationOptions {
  userId: number;
  prompt: string;
  pictureSetId: number | null;
  aspectRatio: AspectRatio;
  portraitSetId?: number;
  regenerateCount?: number;
  editMode?: 'freestyle' | 'artist';
}

export interface ImageGenerationResult {
  pictures: PictureViewModel[];
  jobs: ImageGenerationJobViewModel[];
  status: PictureStatus;
  isPreview: boolean;
}
