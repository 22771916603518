import { PictureTypes } from '@api-types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const maxAppHeightState = atomWithStorage('maxHeight', 0);
export const maxAppItemsAtom = atomWithStorage('maxItems', 0);
export const appShowAllAtom = atomWithStorage('showAll', [] as string[]);
export const appScrollPositionAtom = atom<{ [category: string]: number }>({});
export const loadSupportBeaconScriptAtom = atom(false);
export const showSupportBeaconAtom = atom(false);
export const completedToursAtom = atomWithStorage<string[]>('completedTours', []);
export const userDownloadsAtom = atom<number | null>(0);
export const lastInfoMessageTimeAtom = atomWithStorage<number | null>('lastInfoMessageTime', null);
export const pictureSetsDataAtom = atom<PictureTypes.PictureSetViewModel[] | null>(null);
export const pictureSetsDataIsLoadedAtom = atom(false);
export const userHasNoPictureSetsAtom = atom<boolean | null>(null);
export const appGenerationModeAtom = atomWithStorage<'portrait' | 'chat' | null>('appGenerationMode', null);
export const appActiveSectionModeAtom = atomWithStorage<AppActiveSection | null>('appActiveSection', null);
export const welcomeModeAtom = atom(false);
export const userCoinsAtom = atom<number | null>(null);
export const userTrainingsAtom = atom<number | null>(null);

export enum AppActiveSection {
  Chat = 'chat',
  Portraits = 'portraits',
}
